/* global $, grecaptcha */

'use strict';

/**
 * @description verifies recaptcha v3
 * @param {Object} $form - From jquery object
 * @param {Function} callback - callback function
 */
function verify($form, callback) {
    if (!$('#recaptcha-v3-sitekey').length || !$('#recaptcha-v3-sitekey').val()) {
        return callback();
    }
    var siteKey = $form.find('#recaptcha-v3-sitekey').val();
    var action = $form.find('[name="g-recaptcha-action"]').val();
    return grecaptcha.ready(function() {
        grecaptcha.execute(siteKey, { action: action }).then(function (token) {
            $form.find('[name="g-recaptcha-response"]').val(token);
            return callback();
        });
    });    
}

module.exports = {
    verify: verify
};
