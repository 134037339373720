'use strict';

var store = require('store/components/footer');
var recaptcha = require('../components/recaptcha_v3');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/**
 * @description submit email signup form
 * @param {Object} form - jquery object of register form
 * @param {String} url - url to submit form to
 * @param {Object} button - button that was clicked for sign-up
 */
function submitEmailSignUpForm(form, url, button) {
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            displayMessage(data, button);
        },
        error: function (err) {
            displayMessage(err, button);
        }
    });
}

module.exports = $.extend(store, {
    emailSignUp: function () {
        $('#footer-emailsignup').submit(function(e) {
            e.preventDefault();
            var form = $(this);
            var url = $(this).attr('action');
            var button = $(this);
            $.spinner().start();
            $(this).attr('disabled', true);

            // verify reCaptcha then submit form
            recaptcha.verify(form, function () {
                submitEmailSignUpForm(form, url, button);
            });

            return false;
        });
    }
});