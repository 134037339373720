'use strict';

const { trim } = require("jquery");

/**
 * Initialize Float Labels
 */
function init() {
    let floatInputs = "input[type='date'], input[type='email'], input[type='password'], input[type='search']"
        + ", input[type='tel'], input[type='text'], input[type='url'], select, input[type='number']";
    let $formControls = $(floatInputs);

    $('form').addClass('floating'); // add global utility class to forms for manipulating specific elements

    $formControls.each(function() {
        let $currEl = $(this);
        let $label = $(this).parents('.form-group').find('label');
        let required = $(this).parents('.required').length > 0 ? true : false;
        let labelText;

        if($currEl.hasClass('no-float')) {
            return;
        }

        if(required) {
            labelText = '* ' + $label.text().trim();
        } else {
            labelText = $label.text().trim();
        }

        $(this).parents('.form-group').addClass('floating-label');

        // move label to follow input
        $label.insertAfter($(this));

        // if no placeholder is set, use label text
        if(!$(this).attr('placeholder')){
            $(this).attr('placeholder', labelText);
        } else if(required) {
            var currPlaceholder = $(this).attr('placeholder')
            $(this).attr('placeholder', '* ' + currPlaceholder);
        }

        // detect whether select has a empty "placeholder" value as first option, or an actual value
        if($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');

            $currEl.parents('.form-group').addClass('floating-select');

            if($.trim($firstOption.html()).length) {
                $currEl.parents('.form-group').addClass('default-set');
            }
        }
    });

    // select float label functionality
    $('.form-control').on('focus blur change input', function (e) {
        var $currEl = $(this);

        if($currEl.hasClass('no-float')) {
            return;
        }

        if($currEl.is('select')) {
            var $firstOption = $currEl.find('option:first-child');
            var labelText = '* ' + $currEl.siblings('label').text().trim();
            var uppercaseLabel = labelText.toUpperCase();

            // if first option is empty, then assign the label text as "placeholder" option
            if(!$.trim($firstOption.html()).length) {
                $firstOption.text(uppercaseLabel).attr('label', uppercaseLabel);
            }

            // if the first value is a "placeholder" apply float label style, if it has a default value apply "selected" style
            if($firstOption.is(':selected')) { // first option is selected
                if(!$currEl.parents('.default-set').length) { // first option is a placeholder and not a "default"
                    $currEl.parent().removeClass('focused');
                } else { // first option is a "default"
                    $currEl.parent().addClass('focused');
                }
            } else { // first option is not selected
                $currEl.parents('.form-group').toggleClass('focused', ((e.type === 'focus' || (this && this.value && !!this.value.length)) && (!$firstOption.is(':selected'))));
            }
        } else { // not a select element
            $currEl.parents('.form-group').toggleClass('focused', (e.type === 'focus' || (this && this.value && !!this.value.length)));
        }
    }).trigger('blur');
}

module.exports = function () {
    var enableFloatLabels = $('.page').data('features').enableFloatLabels;

    if(enableFloatLabels) {
        init();
    }
};
