'use strict';

window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    $('body').addClass('js-enabled'); // enables animation & prevents images from being invisible
    processInclude(require('store/components/menu'));
    processInclude(require('store/components/header'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/footer'));
    processInclude(require('store/components/miniCart'));
    processInclude(require('store/components/floatLabels'));
    processInclude(require('store/components/collapsibleItem'));
    processInclude(require('store/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('core/components/toolTip'));
    processInclude(require('store/accelerator/slider'));
    processInclude(require('store/product/addTileToCart'));
    processInclude(require('store/components/wineFinder'));
    processInclude(require('store/components/tagmanager'));
    processInclude(require('store/giftcert/giftcert'));
    processInclude(require('store/components/animation'));
    
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
require('core/thirdParty/smartResize');
require('store/thirdParty/jquery.waypoints');
require('store/thirdParty/matchHeight');
require('store/thirdParty/bootstrap4-toggle');
require('core/thirdParty/hoverIntent');
