'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

var isDesktop = function (element) {
    return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
};

/**
 * Behavior when mouse enters nav menu item
 */
function navMouseEnter() {
    if (isDesktop(this)) {
        var activeMenuHeight = $(this).find('.dropdown-item.active').children('.dropdown-menu').outerHeight();
        $(this).find('.dropdown-item.active').parent('.dropdown-menu-list').css('height', activeMenuHeight);
        $(this).addClass('show');
        $(this).children('.dropdown-menu').addClass('show');
        $(this).children('.dropdown-toggle').attr('aria-expanded', 'true');
    }
}

/**
 * Behavior when mouse enters nav menu item
 */
function navMouseLeave() {
    if (isDesktop(this)) {
        $(this).find('.dropdown-item.active').parent('.dropdown-menu-list').css('height', '');
        $(this).removeClass('show');
        $(this).children('.dropdown-menu').removeClass('show');
        $(this).children('.nav-link').attr('aria-expanded', 'false');
    }
}

/**
 * Reset nav statesbetwen desktop/mobile transition
 */
function navReset() {
    $('.navbar-nav > li').each(function () {
        $(this).closest('.dropdown').children('.dropdown-menu').children('.top-category')
            .detach();
        $(this).closest('.dropdown').children('.dropdown-menu').children('.nav-menu')
            .detach();
        $(this).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
        $(this).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
        $(this).closest('.dropdown.show').removeClass('show');
        $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    });

    $('.dropdown-item').removeClass('active');

    // reset nav between desktop & mobile
    if (window.matchMedia('(min-width: 62em)').matches) {
        $('.dropdown-menu').css('padding-bottom', '').css('top', '');
        $('.dropdown-item').removeClass('active');
        $('.cat-shop').find('.cat-shop-by-style').addClass('active');
    }
}

/**
* Check for Touch Support
*/

function checkTouch() {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
}

/**
 * Hover In functionality for Mega Nav Sub-menu
 */
// function subNavHoverIn() {
//     $('.dropdown-item').removeClass('active');
//     $(this).addClass('active');
// }

module.exports = function () {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    var megaMenuToggle = $('.nav-level-1 > .nav-link > .fas');

    var isTouch = checkTouch();

    if (!isTouch) {
        megaMenuToggle.addClass('no-touch');
    }

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu .back').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');

                if ($(this).parent('.nav-level-1, .account').length) {
                    $(this).parent().children('.dropdown-menu').css('top', $('.close-menu').innerHeight())
                        .css('padding-bottom', $('.close-menu').innerHeight() + 30);
                }

                if ($(this).parent('.cat-shop').length) {
                    $(this).parent('.cat-shop').find('.cat-shop-by-style > .dropdown-link, .cat-shop-by-region > .dropdown-link').removeClass('dropdown-toggle')
                        .removeAttr('data-toggle');
                    var shopText = $(this).attr('data-shop-all');
                    $(this).parent('.cat-shop').find('.top-category > .nav-link > .cat-name-text').text(shopText);
                }

                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                // $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        }).parent().hoverIntent({
            over: navMouseEnter,
            out: navMouseLeave,
            timeout: 200
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
        $('.navbar-toggler').focus();
        $('body').removeClass('noscroll');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.dropdown-item').removeClass('active');
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        // $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        $('body').addClass('noscroll');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('click', '#myaccount', function (e) {
        e.preventDefault();
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').toggleClass('show');
            if ($('.navbar-header .user').attr('aria-hidden') === 'true') {
                $('.navbar-header .user').attr('aria-expanded', 'false');
            } else {
                $('.navbar-header .user').attr('aria-hidden', 'true');
            }
        }
    });

    $('body').on('click', function (e) {
        if ($('.navbar-header .user .popover.show').length > 0) {
            if ($(e.target).closest('.user').length === 0) {
                $('.navbar-header .user .popover').removeClass('show');
                $('.navbar-header .user').attr('aria-hidden', 'true');
            }
        }
    });

    if (window.matchMedia('(min-width: 48em)').matches) {
        $('.nav-level-1 > .nav-link > .fas:not(.no-touch)').on('click', function (e) {
            var $parentLI = $(this).parents('.nav-level-1');
            e.preventDefault();
            e.stopPropagation();
            // Check if currently active
            if ($(this).hasClass('js-active')) {
                // Close mega menu if currently active/open
                $parentLI.removeClass('show').find('.dropdown-menu').removeClass('show');
                $(this).removeClass('js-active');
            } else {
                // Close other mega menus if active/open
                $parentLI.removeClass('show').find('.dropdown-menu').removeClass('show');
                $(this).parents('.navbar-nav').find('.nav-item .js-active').removeClass('js-active');
                // Open selected mega menu
                $parentLI.addClass('show').find('.dropdown-menu').addClass('show');
                $(this).addClass('js-active');
            }

        });
    }

    // $('.dropdown-item').hoverIntent({
    //     over: subNavHoverIn,
    //     timeout: 200
    // });

    $('.cat-shop .nav-level-2>.dropdown-link').on('click', function (e) {
        if (isDesktop(this)) {
            if (!$(this).parent('.cat-shop-all-wine').length) {
                e.preventDefault();
                $('.dropdown-menu-list').css('height', '');
                $('.dropdown-item').removeClass('active');
                var activeMenuHeight = $(this).siblings('.dropdown-menu').outerHeight();
                $(this).parents('.dropdown-menu-list').css('height', activeMenuHeight);
                $(this).parent('.dropdown-item').addClass('active');
            }
        }
    });

    $(window).smartresize(function () {
        navReset();
    });
};
