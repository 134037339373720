'use strict';

var $scrollTop = $(window).scrollTop();
var $body = $('body');
var $header = $('.page > header');
var $hTop = $header.find('.header-top');
var $searchTakeover = $('.search-takeover');
var scrollAnimate = require('./scrollAnimate');

/**
 * Intercept hash location for Chrome and scroll to
 *
 * @return {undefined}
 */
function chromeIncomingAnchor() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (window.location.hash && isChrome) {
        setTimeout(function () {
            var hash = window.location.hash;
            var pageAnchor = $(hash);
            window.location.replace(hash);
            scrollAnimate(pageAnchor);
        }, 1000);
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} scrollPos - current scroll position
 * @param {number} offset - offset of header element
 * @param {string} breakpoint - current breakpoint mobile || desktop
 * @return {undefined}
 */
function setScrollFx(scrollPos, offset, breakpoint) {
    // var scrollDir = scrollPos > $scrollTop ? 'down' : 'up';
    var hOffset = parseInt($header.css('top'));

    // // Toggle utility class on scroll
    // scrollPos > 1 ? $body.addClass('is-scrolled') : $body.removeClass('is-scrolled');

    // // Set scroll FX when applicable - condense header on scroll down only, toggle utility class
    // if (scrollDir === 'down') {
    //     // Scroll down FX
    //     $header.css('top', offset).addClass('offset');
    // } else if (scrollDir === 'up') {
    //     // Scroll up FX
    //     $header.css('top', 0).removeClass('offset');
    // }

    // Check for header offset, set body padding, suggestions position
    if ($header.hasClass('offset')) {
        $body.css('padding-top', ($header.height() + hOffset) + 10);
        $searchTakeover.css('top', ($header.height() + hOffset));
        if (breakpoint === 'mobile') {
            $('.refinement-bar').height($(window).outerHeight(true) - $header.height()).css('top', ($header.height() + hOffset));
        } else {
            $('.refinement-bar').attr('style', '');
        }
    } else {
        $body.css('padding-top', $header.height());
        $searchTakeover.css('top', $header.height());
        if (breakpoint === 'mobile') {
            $('.refinement-bar').height($(window).outerHeight(true) - $header.height()).css('top', $header.height());
        } else {
            $('.refinement-bar').attr('style', '');
        }
    }

    // // Reset scroll position, prevent negative scroll
    // $scrollTop = scrollPos <= 0 ? 0 : scrollPos;
}

/**
 * Set Header Values for use with sticky functionality
 *
 * @return {undefined}
 */
function setHeader() {
    var $scrollPos = $(window).scrollTop();
    var $offsetH = -($hTop.outerHeight());
    var $bp = window.matchMedia('(max-width: 62em)').matches ? 'mobile' : 'desktop';

    // Set Header FX
    setScrollFx($scrollPos, $offsetH, $bp);
}

/**
 * Refine Ship To List
 *
 */
function prepareShipTo() {
    $('.ship-to-selection select option').filter(function () {
        return !this.value || $.trim(this.value).length === 0 || $.trim(this.text).length === 0 || this.value === 'OTHER';
    }).remove();
}

/**
 * Set Copyright Date in Footer
 *
 * @return {undefined}
 */
function setCopyrightYear() {
    var currYear = new Date().getFullYear();

    $('footer .copy-year').html(currYear);
}

module.exports = function () {
    chromeIncomingAnchor();
    setCopyrightYear();
    setHeader();
    prepareShipTo();

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    var screenWidth = $(window).width();

    $('.header-top .close').on('click', function () {
        $('.header-top').addClass('d-none');
        setHeader();
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-top').removeClass('d-none');
        setHeader();
    }

    $('body').on('change', '.header-main #ship-to-state', function () {
        var state = $(this).val();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            method: 'GET',
            data: {state: state},
            dataType: 'json',
            success: function () {
                window.location.reload();
            }
        });
    });

    $('.search-trigger').on('click', function () {
        setHeader();
    });

    $(window).smartresize(function () {
        if ($(this).width() !== screenWidth) {
            screenWidth = $(this).width();
            setHeader();
        }
    });

    // $(window).on('scroll', function () {
    //     setHeader();
    // });
};
