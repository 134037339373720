'use strict';

module.exports = function () {
    var $collection = $('.fade-in-up');

    $collection.each(function () {
        new Waypoint({
            element: this,
            handler: function(direction) {
                $(this.element).addClass('visible');
            },
            offset: '80%'
        })
    });

    $(document).on('lazyloaded', function(e) {
        Waypoint.refreshAll();
    });

    if ($('#winery-rutherford-hill').length > 0) {
        $('html, body').animate({
            scrollTop: $('#winery-rutherford-hill .experience-dynamic-productList').offset().top - 200
        }, 500);
    }
};
