'use strict';

/**
 * @param {Object} productObject - product data
 * @param {string} quantity - product quantity
 * @param {string} source - data source
 * @param {string} currency - currency code used
 * @param {string} emailAddress - user email address
 * @description Click event for add product to cart
 */
 function addToCart(productObject, quantity, source, currency, emailAddress) {
    var quantObj = { quantity: parseInt(quantity, 10) };
    var obj = {
        userEmail: emailAddress,
        event: 'addToCart',
        event_info: {
            label: source
        },
        ecommerce: {
            currencyCode: currency,
            add: {
                products: []
            }
        }
    };
    obj.ecommerce.add.products.push($.extend(productObject, quantObj));
    dataLayer.push(obj);
}

module.exports = {
    addToCart: addToCart
};