'use strict';

var wineFinder = {};

wineFinder.callModal = function () {
    $('body').on('click', '.btn.wine-finder.btn, a.wine-finder', function (e) {
        e.preventDefault();

        $.ajax({
            url: $(this).data('href'),
            type: 'get',
            dataType: 'html',
            success: function (responseData) {
                $('#wineFinderModal .modal-body').html(responseData);
            }
        });
    });
};

module.exports = wineFinder;
