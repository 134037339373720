'use strict';

module.exports = function (element) {
    var hHeight = $('.page > header').height();
    var position = 0;
    if (element && element.length) {
        if (element.hasClass('anchor-offset')) {
            position = (element.offset().top);
        } else {
            position = (element.offset().top - hHeight);
        }
    }
    $('html, body').animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
