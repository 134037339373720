'use strict';

require('core/thirdParty/slick');

module.exports = function () {

    $('.slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.product-slider').on('init', function (event, slick) {
        $(this).find('.product .tile-tag').matchHeight();
        $(this).find('.product .tile-body').matchHeight();
        $(this).find('.product .tile-buttons').matchHeight();
        $(this).find('.product').matchHeight();
    });

    $('.product-slider').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false
                }
            }
        ]
    });
};
