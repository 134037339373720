'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var scrollAnimate = require('../components/scrollAnimate');
var Cleave = require('cleave.js').default;
var tagManagerHelper = require('../components/tagmanagerHelper');

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast

    if ($('.add-to-cart-messages').length === 0) {
        $('body').append(
            '<div class="add-to-cart-messages"></div>'
        );
    }

    $('.add-to-cart-messages').append(
        '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
        + response.message
        + '</div>'
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

/**
 * Used to initiate ajax call and update the form
 * @param {Object} form - gift certificate form
 * @param {boolean} isUpdate - boolean value to check if it is update action
 * @return {boolean} false
 */
var updateGiftCertForm = function (form, isUpdate) {
    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
            } else {
                if (!isUpdate) {
                    handlePostCartAdd(data);
                    var $csrf_token = $('input[name$="csrf_token"]');
                    var csrf_token_value = $csrf_token.val();
                    form.find('input,textarea').val('');
                    $csrf_token.val(csrf_token_value);
                    return false;
                }
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else if (isUpdate) {
                $('.error-gift-certificate').html('');
                createErrorNotification($('.error-gift-certificate'), err.responseJSON.errorMessage);
                scrollAnimate($('.error-gift-certificate'));
            } else {
                createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
            }
            form.spinner().stop();
        }
    });
    return false;
};

/**
 * Create gift certificate add to cart dataLayer object,
 * as close to an equivalent to the CustomTagManager getProductData function output as possible
 * @param {*} form JQuery object that contains a validated form object
 */
function createGiftCertAddToCartGtmObj ($form) {
    if(dataLayer && $form.length > 0) {
        // add all of the available information from the form to the object
        var giftCertProductObj = {
            name: 'Gift Certificate',
            price: parseFloat($form.find('#giftcert_purchase_amount')[0].value),
            message: $form.find('#giftcert_purchase_message')[0].value,
            recipientEmail: $form.find('#giftcert_purchase_recipientEmail')[0].value,
            recipientName: $form.find('#giftcert_purchase_recipient')[0].value,
            senderName: $form.find('#giftcert_purchase_from')[0].value
        }
        // (productObject, quantity, source, currency, emailAddress)
        tagManagerHelper.addToCart(giftCertProductObj, 1, 'PDP', $('.gtmdata-footer').data('currency-code'), '');
    }
}
/**
 * Used to initiate ajax call and check gift card balance
 * @param {Object} form - check balance form
 */
var checkBalance = function (form) {
    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
            } else {
                $('#gift-balance-msg').empty();
                $('#gift-balance-msg.success').html(data.giftCertificate.balance);
            }
        },
        error: function (err) {
            $('#gift-balance-msg').empty();
            form.spinner().stop();
            $('#gift-balance-msg.error').html(err.responseJSON.error);
        }
    });
};

/**
 * Checks for errors and then scrolls to first.
 */
function checkErrors() {
    window.setTimeout(function () {
        var error = $('.is-invalid');
        if (error.length) {
            scrollAnimate(error);
        }
    }, 0);
}

function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }
    button.removeClass('moveToWishlist');
}

module.exports = {
    addToBasket: function () {
        $('.atc-gift-certificate').on('click', function () {
            checkErrors();
        });
        $('form.giftcert').on('submit', function (e) {
            var form = $(this);
            // submit should only fire when the validation has completed successfully 
            createGiftCertAddToCartGtmObj(form);
            e.preventDefault();
            updateGiftCertForm(form, false);
        });
    },
    updateGiftCertificate: function () {
        $('body').on('click', '.update-gift-certificate', function (e) {
            var form = $(this).parent('.giftcert');
            e.preventDefault();
            updateGiftCertForm(form, true);
        });
    },
    checkGiftCertBalance: function () {
        $('body').on('click', '#CheckBalanceButton', function (e) {
            var form = $('.check-balance');
            e.preventDefault();
            checkBalance(form);
        });
    },
    moveToWishlist: function () {
        $('body').on('click', '.moveToWishlist', function (e) {
            e.preventDefault();
            var url = $(this).data('href');
            var pid = $(this).data('pid');
            var button = $(this);
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $(this).attr('disabled', true);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid
                },
                success: function (data) {
                    $('.moveToWishlist').text('Wishlist Added');
                    displayMessage(data, button);
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    },
    amountPreFillValue: function () {
        var $giftCertAmount = $('#giftcert_purchase_amount');
        $('body').on('click', '.purchase_amount__blocks button', function (e) {
            e.preventDefault();
            var $this = $(this);

            $giftCertAmount.val($this.data('value'));
        });
    }
};
