'use strict';

var cart = require('../cart/cart');
var updateMiniCart = true;

/**
 * open minicart and show contents
 */
function showMiniCart() {
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count !== 0 && $('.minicart .popover.show').length === 0) {
        if (!updateMiniCart) {
            $('.minicart .popover').addClass('show');
            return;
        }

        $('.minicart .popover').addClass('show');
        $('.minicart .popover').spinner().start();
        $.get(url, function (data) {
            $('.minicart .popover').empty();
            $('.minicart .popover').append(data);
            checkCartLineQtys();
            updateMiniCart = false;
            $.spinner().stop();
        });
    }
}

/**
 * copy of base/components/miniCart with accel miniCart updates
 */
function base() {
    cart();

    var miniCartDismiss;

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        clearTimeout(miniCartDismiss);
        showMiniCart();
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('.minicart').on('mouseleave focusout', function (event) {
        updateMiniCart = true;
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart', function (e, data) {
        var addedToCart = !data.error;
        if (addedToCart) {
            updateMiniCart = true;
            showMiniCart();
            miniCartDismiss = setTimeout(function () {
                $('.minicart').trigger('focusout');
            }, 5000);
        }
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
        var $lineItems = $('.minicart .product-line-item');

        $lineItems.each(function () {
            var $eachTemplate = $(this).find('.ea-template').html();
            var $unitPrice = $(this).find('.unit-price');
            var curQty = parseInt($(this).find('.quantity-input').val());

            if (curQty === 1) {
                $unitPrice.addClass('no-each');
            } else {
                $unitPrice.append($eachTemplate).removeClass('no-each');
            }
        });
        checkCartLineQtys();
    });

    $('body').on('touchstart click', '.minicart-header--close button', function () {
        $('.minicart .popover').removeClass('show');
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

/**
 * Quantity Selector New Design
 */
function checkCartLineQtys() {
    var $atcInputs = $('.minicart .quantity-add-cart_component input.quantity-input, .cart .quantity-add-cart_component input.quantity-input');

    $atcInputs.each(function () {
        var $this = $(this);
        var $parent = $this.parent();
        var $qtyNote = $this.parents('.quantity-add-cart_component').siblings('.quantity-note');
        var maxQtyMessage = $this.attr('data-qty-max-message');
        var curQty = parseInt($this.val());
        var max = parseInt($this.attr('max'));

        if ($qtyNote.length > 0) {
            $qtyNote.empty();
        }

        if (curQty === 1) {
            $parent.find('.quantity-btn--down').attr('disabled', 'disabled');
        }

        if (curQty + 1 > max) {
            $parent.find('.quantity-btn--up').attr('disabled', 'disabled');
            if ($qtyNote.length > 0) {
                $qtyNote.text(maxQtyMessage);
            }
        }
    });
}

/**
 * Quantity Selector New Design
 */
function quantityToggle() {
    var $atcInputs = $('.quantity-add-cart_component input.quantity-input');

    $atcInputs.each(function () {
        var $this = $(this);
        var $parent = $this.parent();
        var $qtyNote = $this.parents('.quantity-add-cart_component').siblings('.quantity-note');
        var maxQtyMessage = $this.attr('data-qty-max-message');
        var curQty = parseInt($this.val());
        var max = parseInt($this.attr('max'));

        if ($qtyNote.length > 0) {
            $qtyNote.empty();
        }

        if (curQty === 1) {
            $parent.find('.quantity-btn--down').attr('disabled', 'disabled');
        }

        if (curQty + 1 > max) {
            $parent.find('.quantity-btn--up').attr('disabled', 'disabled');
            if ($qtyNote.length > 0) {
                $qtyNote.text(maxQtyMessage);
            }
        }
    });

    $('body').on('click', '.quantity-add-cart_component button.quantity-btn', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        var $qtyInput = $parent.find('input.quantity-input');
        var curQty = parseInt($qtyInput.val());
        var min = parseInt($qtyInput.attr('min'));
        var max = parseInt($qtyInput.attr('max'));
        var $qtnMinusBtn = $parent.find('.quantity-btn--down');
        var $qtnAddBtn = $parent.find('.quantity-btn--up');

        if ($this.hasClass('quantity-btn--down')) {
            $qtnAddBtn.removeAttr('disabled', 'disabled');
            if (curQty - 1 < 1) {
                curQty = min;
                $this.attr('disabled', 'disabled');
            } else {
                curQty -= 1;

                if (curQty === 1) {
                    $this.attr('disabled', 'disabled');
                } else {
                    $this.removeAttr('disabled', 'disabled');
                }
            }
        } else {
            $qtnMinusBtn.removeAttr('disabled', 'disabled');
            if (curQty + 1 > max) {
                curQty = max;
                $this.attr('disabled', 'disabled');
            } else {
                curQty += 1;

                if (curQty === max) {
                    $this.attr('disabled', 'disabled');
                } else {
                    $this.removeAttr('disabled', 'disabled');
                }
            }
        }

        $qtyInput.val(curQty).trigger('change');
    });

    $('body').on('change keyup', 'input.quantity-input', function (e) {
        var $this = $(this);
        var $parent = $this.parent();
        var curQty = parseInt($this.val());
        var max = parseInt($this.attr('max'));
        var $qtnMinusBtn = $parent.find('.quantity-btn--down');
        var $qtnAddBtn = $parent.find('.quantity-btn--up');

        $qtnAddBtn.removeAttr('disabled', 'disabled');
        $qtnMinusBtn.removeAttr('disabled', 'disabled');

        if (curQty <= 1) {
            $qtnMinusBtn.attr('disabled', 'disabled');
            $this.val('1');
        }

        if (curQty + 1 > max) {
            $qtnAddBtn.attr('disabled', 'disabled');
            $this.val(max);
        }


    });
}

module.exports = {
    base: base,
    moveToWishlist: moveToWishlist,
    quantityToggle: quantityToggle
};
