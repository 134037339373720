'use strict';

var addToCart = require('./base').addToCart;
var processInclude = require('base/util');

/**
 * add tile to cart
 */

var Tile = {};

function updateAddToCart (element) {
    $(element).on('updateAddToCartFormData', function (e, form) {

        form.pid = form.pid || $(this).data('pid');

        form.quantity = $(this).closest('.quantity-add-cart_component').find('input.quantity-select').val();

        // remove listener
        $(this).off('updateAddToCartFormData');
        $.spinner().start();
        return form;
    });
};

Tile.beforeAddToCart = function () {
    $('body').on('product:beforeAddToCart', function (e, tile) {
        updateAddToCart(tile);
    });
};

Tile.einsteinCarousel = function () {
    processInclude(require('../einsteinCarousel'));
};

Tile.addToCart = addToCart;

module.exports = Tile;
