'use strict';
var tagManagerHelper = require('./tagmanagerHelper');

/**
 * function productClick
 * @description Click event for add product to cart
 * @param {Object} productObject - product object
 */
function productClick(productObject) {
    var obj = {
        event: 'productClick',
        ecommerce: {
            currencyCode: $('.gtmdata-footer').data('currency-code'),
            click: {
                products: []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @param {string} productId - product id to add
 * @description Click event for add product to wishlist
 */
function addToWishlist(productId) {
    var obj = {
        event: 'addToWishlist',
        ecommerce: {
            wishlist: {
                products: []
            }
        }
    };
    obj.ecommerce.wishlist.products.push({ id: productId });
    dataLayer.push(obj);
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 * @param {Object} productObject - product data to remove
 * @param {string} quantity - number of quantity to remove
 */
function removeFromCart(productObject, quantity) {
    var quantObj = { quantity: parseInt(quantity, 10) };
    var obj = {
        userEmail: $('.gtmdata-footer').data('email-address'),
        event: 'removeFromCart',
        ecommerce: {
            currencyCode: $('.gtmdata-footer').data('currency-code'),
            remove: {
                products: []
            }
        }
    };
    obj.ecommerce.remove.products.push($.extend(productObject, quantObj));
    dataLayer.push(obj);
}

/**
 * @function pushEvent
 * @description Convenience method for creating a click event.
 * @param {string} event - action to trigger
 * @param {string} eventCategory - type of event
 * @param {string} eventAction - event action
 * @param {string} eventlabel - label
 */
function pushEvent(event, eventCategory, eventAction, eventlabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventlabel
    });
}

module.exports = {
    account: function () {
        $('form.registration').submit(function () {
            pushEvent('registration', 'User Action', 'Registration Submit', 'Registration');
        });
    },

    cart: function () {
        $('body').on('click', '.tm-removeprod', function () {
            var productID = $(this).data('pid');
            var url = $('.gtm-urls').data('productdata');
            $.ajax({
                url: url,
                data: { pid: productID },
                type: 'get',
                dataType: 'json',
                success: function (responseData) {
                    removeFromCart(responseData, 1);
                }
            });
        });
    },

    checkout: function () {
        var shippingPage = document.querySelector('.shipping-address-form');

        if (shippingPage) {
            var values = '';
            jQuery('.shipping-method-form').each(function () {
                $(this).find('.input-radio').each(function (index) {
                    var el = this;
                    if (index > 0) {
                        values = values + ',';
                    }
                    values = values + el.value;
                });
                pushEvent('shippingImpression', 'Shipping', 'Impression', values);
                values = '';
            });

            $('body').on('click', '#fakeContinueBtn', function () {
                jQuery('.shipping-method-form .input-radio:checked').each(function () {
                    var el = this;
                    pushEvent('shippingSelection', 'Shipping', 'Selection', el.value);
                });
            });
            $('body').on('click', '.button-fancy-large', function () {
                jQuery('.shipping-method-form .input-radio:checked').each(function () {
                    var el = this;
                    pushEvent('shippingSelection', 'Shipping', 'Selection', el.value);
                });
            });
        }
    },

    compare: function () {},

    product: function () {
        $('body').on('click', '#add-to-cart, button.add-to-cart', function () {
            if (!$(this).hasClass('add-to-cart-disabled')) {
                var atcLocation = 'PDP';
                if ($('.search-results').length) {
                    atcLocation = 'PLP';
                }
                tagManagerHelper.addToCart($(this).data('gtmdata'), $('.quantity-select').val(), atcLocation, $(this).attr('data-currency'), $(this).attr('data-email-address'));
            }
        }).on('click', '.add-to-wish-list span', function () {
            addToWishlist($(this).attr('data-pid'));
        });
    },

    search: function () {
        $('body').on('click', '.add-to-cart-global', function () {
            tagManagerHelper.addToCart($.parseJSON($(this).attr('data-gtmdata')), $(this).closest('div').find('[name=Quantity]').val(), 'Quickview', $(this).attr('data-currency'), $(this).attr('data-email-address'));
        }).on('click', '.thumb-link, .name-link, .tile-img-link', function () {
            var productObject = $.parseJSON($(this).attr('data-gtmdata'));
            productClick(productObject);
        });
    },

    storefront: function () {},

    wishlist: function () {},

    // events that should happen on every page
    all: function () {
        $('.has-sub-menu, .mega-nav').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.primary-logo, .logo-home').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $('body').on('click', '.paypal-checkout-btn', function () {
            pushEvent('paypalClick', 'User Action', 'PayPal Express Click', 'PayPal Express Link');
        });
        $('form.footer-subscribe, form.drawer-subscribe').on('submit', function () {
            var obj = {
                event: 'emailSubscribe',
                event_info: {
                    non_interaction: $(this).hasClass('footer-subscribe') ? 'Footer' : 'Lower drawer'
                }
            };
            dataLayer.push(obj);
        });
    }
};
